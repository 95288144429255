import React from "react";

import "./MenuItem.css";

const MenuItem = ({ title, step, text,className }) => (
  <div className={className + " app__menuitem"}  >
    <div className="app__menuitem-head">

    <div className="app__menuitem-price">
        <p className="p__cormorant">{step}</p>
      </div>
      
      <div className="app__menuitem-name">
        <p className="p__cormorant">
          {title}
        </p>
      </div>

     
      
    </div>
    <div className="app__menuitem-sub">
      <p className="p__opensans" style={{ color: "#aaa" }}>
        {text}
      </p>
    </div>
  </div>
);

export default MenuItem;
