import React from "react";
import { SubHeading } from "../../components";
import { images } from "../../constants";
import useDirection from "../../useDirection"
import "./Header.css";
import { useTranslation } from "react-i18next";

const Header = () => {
  const {t} = useTranslation();
  const direction = useDirection();
  console.log("ddd",direction);
  return(
  <div className="app__header app__wrapper " style={{ direction: direction }} id="home">
    <div className="app__wrapper_info">
      <SubHeading title={t("WORD_CHALLENGE_ACCEPTED")} />
      <h1 className="app__header-h1">RAW SOLUTIONS FOR DIGITAL MARKETING</h1>
      <p className="p__opensans" style={{ margin: "2rem 0" }}>
        Let's Go viral together 
      </p>
      {
        <div className="app__wrapper_info_button-container">
          <button type="button" className="custom__button">
            Contact us
          </button>
        </div>
      }
    </div>
    <div className="app_wrapper_img">
      <img src={images.Work} alt="welcome image" className="welcomeImage" />
      
    </div>
  </div>
)}

export default Header;
