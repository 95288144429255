import OurVision from "../assets/ourVision.png";
import Success from "../assets/success.png";
import Creative from "../assets/creative.png";

import Work from "../assets/work.png";
import LogoLight from "../assets/logoLight.png";
import upbg from "../assets/upbg.png";
import logoDark from "../assets/logoDark.png";
export default {
  OurVision,
  Success,
  Creative,

  Work,
  LogoLight,
  upbg,
  logoDark,
};
