import React from "react";
import { FooterOverlay, CTA } from "../../components";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import "./Footer.css";
import { images } from "../../constants";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => (
  <div className="app__footer section__padding">
    <FooterOverlay />
    <CTA />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans p__grey">Dubai, United Arab Emirates</p>
        <p className="p__opensans p__grey">info@rawsolutionsofficial.com</p>
        <p className="p__opensans p__grey">+971 52 623 7444</p>
      </div>
      <div className="app__footer-links_logo">
        <img src={images.logoDark} alt="footer_logo" />
        <p className="p__opensans p__grey">
          "The best way to find yourself is to lose yourself in the service of
          others.”
        </p>
        <div className="app__footer-links_icons">
          <a
            href="https://www.facebook.com/Rawsolutionsofficial?mibextid=ZbWKwL"
            target="_blank"
          >
            <FiFacebook />
          </a>
          <a
            href="https://www.linkedin.com/company/rawsolutions-for-digital-marketing/"
            target="_blank"
          >
            <FiLinkedin />
          </a>
          <a
            href="https://www.instagram.com/rawsolutionsofficial?igsh=ODA1NTc5OTg5Nw=="
            target="_blank"
          >
            <FiInstagram />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=971526237444&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans p__grey">Monday-Friday:</p>
        <p className="p__opensans p__grey">08:00 am -12:00 am</p>
        <p className="p__opensans p__grey">Saturday-Sunday:</p>
        <p className="p__opensans p__grey">07:00am -11:00 pm</p>
      </div>
    </div>
    <div className="footer__copyright">
      <p className="p__opensans p__grey">
        All Rights Reserved. @rawsolutionsofficial 2023 ©
      </p>
    </div>
  </div>
);

export default Footer;
