import images from "./images";

const webDevelopment = [
  {
    title: "Innovation",
    step: "01",
    text: "We embrace creativity and stay at the forefront of industry trends to provide cutting-edge solutions." 
   },
  {
    title: "Integrity",
    step: "02",
    text: "Honesty and transparency are at the heart of everything we do, building trust with our clients and partners.",
  },
  {
    title: "Collaboration",
    step: "03",
    text: "We believe in the power of collaboration and work closely with our clients to achieve shared goals.",
  },
  {
    title: "Results-Driven",
    step: "04",
    text: "We are committed to delivering measurable results that contribute to the growth and success of our clients' businesses.",
  },
  {
    title: "Learning",
    step: "05",
    text: "Our team is dedicated to ongoing learning and professional development to stay ahead in the dynamic digital marketing landscape.",
  },
];

const digitalMarketing = [
  {
    title: "Social Media Marketing",
    step: "01",
    text: "Engage your audience and build brand loyalty through effective social media strategies.",
  },
  {
    title: "Content Marketing",
    step: "02",
    text: "Create compelling content that resonates with your target audience, driving brand awareness and customer engagement.",
  },
  {
    title: "Email Marketing",
    step: "03",
    text: "Nurture leads and build lasting relationships through personalized and impactful email campaigns.",
  },
  {
    title: "Pay-Per-Click Advertising",
    step: "04",
    text: "Maximize your ROI with targeted and data-driven PPC campaigns across various platforms. ",
  },
  {
    title: "Web Design and Development",
    step: "05",
    text: "Ensure a seamless user experience and a visually appealing online presence with our expert web design and development services.",
  },
  {
    title: "APP Design and Development",
    step: "06",
    text: "Elevate your digital presence with our exceptional app design and development services. ",
  },
  {
    title: "Search Engine Optimization",
    step: "07",
    text: "Boost your online visibility and organic traffic with our comprehensive SEO strategies",
  },
  {
    title: "Analytics and Reporting",
    step: "08",
    text: "Gain valuable insights into your digital marketing performance with our in-depth analytics and reporting services.",
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: "Bib Gourmond",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award01,
    title: "Rising Star",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award05,
    title: "AA Hospitality",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award03,
    title: "Outstanding OurVision",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
];

export default { webDevelopment, digitalMarketing, awards };
