import React, { useRef } from "react";
import SubHeading from "../SubHeading/SubHeading";
import emailjs from "@emailjs/browser";
import "./CTA.css";

const CTA = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if(form.current.user_city.value!=="unSelected")
    {
      console.log("submited");
      emailjs
      .sendForm(
        "service_0mnb1us",
        "template_25ohu4n",
        form.current,
        "2aPedJDIA6EadTh9j"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
    
  };

  return (
    <div className="app__CTA" id="contact">
      <div className="app__CTA-heading">
        <SubHeading title="Contact us" />
        <h1 className="headtext__cormorant">Contact Us</h1>
        <p className="p__opensans">And never miss latest Updates!</p>
      </div>
      <form className="app__CTA-input box" ref={form} onSubmit={sendEmail}>
        <input type="text" name="user_name" placeholder="Enter your name" />
        <input
          type="email"
          name="user_email"
          placeholder="Enter your Email Address"
        />
        <input type="text" name="user_number" placeholder="Enter your number" />
        <select name="user_city" placeholder="Select City">
          <option value="unSelected">select city</option>
            <option value="AbuDhabi">Abu Dhabi</option>
            <option value="Dubai">Dubai</option>
            <option value="Sharjah">Sharjah</option>
            <option value="Ajman">Ajman</option>
            <option value="UmmAlQuwain">Umm Al Quwain</option>
            <option value="RasAlKhaimah">Ras Al Khaimah</option>
            <option value="Fujairah ">Fujairah </option>
            <option value="AlAin">Al Ain</option>
        </select>
        <textarea
          className="letter"
          type="text"
          name="message"
          placeholder="Enter your message"
        />
        <input className="custom__button" type="submit" value="Send" />
      </form>
    </div>
  );
};

export default CTA;
