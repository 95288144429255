import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { FiLoader } from "react-icons/fi";
import "./i18n";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
});

ReactDOM.render(
    <Suspense
    fallback={<FiLoader />}
  >
  <I18nextProvider i18n={i18next}>
    <App  />
  </I18nextProvider>
  </Suspense>,
  document.getElementById("root")
);
