import { useEffect, useState } from 'react';
     import i18n from './i18n';

     const useDirection = () => {
      console.log("i18n.language",i18n.language);
       const [direction, setDirection] = useState(i18n.language === 'ar' ? 'rtl' : 'ltr');

       useEffect(() => {
         document.body.dir = direction;
       }, [direction]);

       return direction;
     };

     export default useDirection;