import React, { useState } from "react";
import { GiCancel, GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.LogoLight} alt="app logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home">Home</a>
        </li>
        <li className="p__opensans">
          <a href="#aboutus">About</a>
        </li>
        <li className="p__opensans">
          <a href="#menu">Services</a>
        </li>

        <li className="p__opensans">
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="app__navbar-login">
        <a
          href="https://www.instagram.com/rawsolutionsofficial?igsh=ODA1NTc5OTg5Nw=="
          target="_blank"
          className="p__opensans"
        >
          <FaInstagram /> Instagram 
        </a>
        <div />
        <a
          href="https://api.whatsapp.com/send/?phone=971526237444&text&type=phone_number&app_absent=0"
          target="_blank"
          className="p__opensans"
        >
           <FaWhatsapp /> +971 52 623 7444
        </a>
      </div>
      <div className="app__navbar-smallscreen ">
        <GiHamburgerMenu
          color="#fff"
          fomntSize={27}
          onClick={() => {
            setToggle(true);
          }}
        />
        {toggle && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <IoMdClose
              fontSize={27}
              className="overlay__close"
              onClick={() => {
                setToggle(false);
              }}
            />
            <div className="app__navbar-smallscreen_logo">
              <img src={images.LogoLight} alt="app logo" />
            </div>
            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <a
                  href="#home"
                  onClick={() => {
                    setToggle(false);
                  }}
                >
                  Home
                </a>
              </li>
              <li className="p__opensans">
                <a
                  href="#aboutus"
                  onClick={() => {
                    setToggle(false);
                  }}
                >
                  About
                </a>
              </li>
              <li className="p__opensans">
                <a
                  href="#menu"
                  onClick={() => {
                    setToggle(false);
                  }}
                >
                  Menu
                </a>
              </li>

              <li className="p__opensans">
                <a
                  href="#contact"
                  onClick={() => {
                    setToggle(false);
                  }}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};
export default Navbar;
