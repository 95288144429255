import React from "react";
import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./OurVision.css";

const OurVision = () => (
  <div className="app__OurVision app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.OurVision} alt="OurVision" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="OUR VISION" className="app__OurVision-content_text" />
      <h1 className="headtext__cormorant">What we believe in</h1>

      <div className="app__OurVision-content">
        <div className="app__OurVision-content_quote">
          
          <p className="p__opensans app__OurVision-content_text">
            we envision a future where businesses seamlessly  navigate the digital
          </p>
        </div>
        <p className="p__opensans app__OurVision-content_text">
        
          realm, empowered by strategic and innovative digital marketing
          solutions.
        </p>
      </div>
      
    </div>
  </div>
);

export default OurVision;
