import React from "react";
import { images } from "../../constants";
import "./AboutUs.css";
import Menu from "../Menu/SpecialMenu"
const AboutUs = () => (
  
  <div
    className="app__aboutus app__bg  "
    id="aboutus"
  >
    
    <img src={images.upbg} className="svg-svg"/>

    <div className="app__aboutus-content flex__cenetr">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        
        <p className="p__opensans">
        RAW Solutions is a leading digital marketing agency dedicated to helping
businesses thrive in the online world. With a team of passionate experts and a
results-driven approach, we aim to elevate your brand, drive meaningful
engagement, and boost your revenue.
        </p>
        <button type="button" className="custom__button">
          Know More
        </button>
      </div>

      <div className="app__aboutus-content_Success flex__center">
        <img src={images.Success} alt="Success" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our Mission</h1>
        <p className="p__opensans">
        our mission is to empower businesses of
all sizes to succeed in the digital landscape.
We strive to deliver innovative and
effective digital marketing
solutions that not only meet
but exceed our clients'
expectations.

        </p>
        <button type="button" className="custom__button">
          Know More
        </button>
      </div>
    </div>
    
  </div>
);

export default AboutUs;


{
  /*
  <svg
        class="border shadow-md"
        viewBox="0 0 1307.68 735.57"
        style={{width: "1307.68px", height: "735.57px",}}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <rect
          class="bg"
          id="bg"
          x="0"
          y="0"
          width="1307.68"
          height="735.57"
          fill="#050505"
        ></rect>
        <g transform="rotate(193 653.84 367.785)">
          <path
            d="M -653.84 595.57 S -447.00 518.57
            0.00 595.57 316.92 498.57
            653.84 595.57 687.68 577.57
            1307.68 595.57 1624.60 561.57
            1961.52 595.57 h 110 V 1335.5700000000002 H -653.84 Z"
            fill="#613A92"
            style={{zIndex:"10000"}}
          ></path>
          <path
            d="M -653.84 165.00 S -488.00 92.50
            0.00 165.00 316.92 66.00
            653.84 165.00 970.76 70.00
            1307.68 165.00 1624.60 92.50
            1961.52 165.00 h 110 V -600 H -653.84 Z"
            fill="#070E0E"
          ></path>
        </g>
      </svg>
  */
}