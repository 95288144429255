import React, { useEffect, useState } from "react";
import { SubHeading, MenuItem } from "../../components";
import { data } from "../../constants";
import "./SpecialMenu.css";

const SpecialMenu = () => {
  const [animated, setAnimated] = useState(true)
  const [animated2, setAnimated2] = useState(true)
  const [animated3, setAnimated3] = useState(true)

  
  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <div className="app__specialMenu-title">
        <SubHeading title="Plan that fits your project" />
        <h1 className="headtext__cormorant">discover</h1>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_wine flex__Center">
          <p className="app__specialMenu-menu_heading">Our Values</p>
          <div className="animate app__specialMenu-menu_items padding">
            {data.webDevelopment.map((step, index) => (
              <MenuItem
                // className={animated?"animate":"stopAnimate"}
                // onMouseLeave={()=>updateAnimatedState()}
                key={step.title + index}
                title={step.title}
                step={step.step}
                text={step.text}
              />
            ))}
          </div>
        </div>

        <div className="app__specialMenu-menu_cocktails flex__Center">
          <p className="app__specialMenu-menu_heading">Our Services</p>
          <div className="animate2 app__specialMenu-menu_items">
            {data.digitalMarketing.map((step, index) => {
              if(index<5)
               return(
              <MenuItem
              // className={animated?"animate2":"stopAnimate"}
             
                key={step.title + index}
                title={step.title}
                step={step.step}
                text={step.text}
              />
            )})}
          </div>
          <div className="animate3 app__specialMenu-menu_items paddingXL">
            {data.digitalMarketing.map((step, index) => {
              if(index>5)
               return(
              <MenuItem
              // className={animated?"animate3":"stopAnimate"}
                key={step.title + index}
                title={step.title}
                step={step.step}
                text={step.text}
              />
            )})}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "15px" }} typeof="button" className="custom__button">
        Work With Us
      </div>
    </div>
  );
};

export default SpecialMenu;